import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import {
  Table,
  Button,
  Container,
  Col,
  Row,
  Pagination,
} from "react-bootstrap";
import { getDuration, roundDecimal, formatDateTime } from "../../lib/Extensions";
import { getClientProfile, getDecisionEngine } from '../../utils/helper'
import axios from "@aws-amplify/storage/node_modules/axios";

const VehicleJourneysInner = ({ children }) => {
  let history = useHistory();

  let vehicleId;
  let currentPage = 1;
  const pageLimit = 10;

  const [sessions, setSessions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [allVehicles, setAllVehicles] = useState([]);
  const [paginationItems, setPaginationItems] = useState([]);
  const [sessionMetrices, setSessionMetrices] = useState([]);

  let query = new URLSearchParams(useLocation().search);

  const vehicleJourneyQuery = `
  {
    listSessions (limit: ${pageLimit}, nextToken: "NEXTTOKENVALUE", clientId: "CLIENTID") {
      items {
        id
        vehicle_id
        run_config_id
        start
        end
        errors_count
      }
      nextToken
      total
    }
    vehicles (clientId: "CLIENTID") {
      id
      registration_number
      vin
    }
  }`;

  const sessionMetricesQuery = `
  {
    sessionMetricesBySessionIds (SessionIds: [SESSIONIDS]) {
      hubJourneyData
      data {
        VehicleId
        SessionId
        EventTimestamp
        Velocity
        BatteryLevel
        Longitude
        Latitude
        AVMode
        VehicleTeleopStatus
        VehicleStatus
        LastUpdated
        TotalDistance
        TotalDistanceInAuto
        TotalDistanceInManual
        TotalDistanceInTeleop
        AverageSpeed
      }
    }
  }`;

  useEffect(() => {
    fetchJourneys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchJourneys();
  };

  async function fetchJourneys() {

    let clientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access journey data.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access journey data for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          clientId = res.client
          document.getElementById('api-result').innerHTML = `<div class='yellow'>User has privilege to access journey data for ${clientId} client.</div>`
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1") {
      return
    }

    setSessions([]);

    vehicleId = query.get("vehicleId");

    const nextTokenValue = currentPage === 1 ? "" : `${currentPage}`;
    const graphQlQuery = vehicleJourneyQuery
      .replace("VEHICLEIDVALUE", vehicleId)
      .replace("NEXTTOKENVALUE", nextTokenValue)
      .replaceAll("CLIENTID", clientId)

    const apiData = await API.graphql(
      graphqlOperation(
        graphQlQuery
      )
    );
    const total = apiData.data.listSessions.total;
    const nextToken = apiData.data.listSessions.nextToken;

    const vehicleData = apiData.data.vehicles;
    const tempSessions = apiData.data.listSessions.items;

    // Get related sessionMetrices
    const sessionIds = tempSessions
      .map((x) => `"${x.id}"`);

    const axiosOptions = {
      headers: {
        'x-api-key': `${process.env.REACT_APP_APIKEY}`,
      },
      withCredentials: (document.location.hostname === "localhost" || document.location.hostname === "127.0.0.1") ? false : true
    }
    const apiData2 = await axios.post(
      `${process.env.REACT_APP_ZEPHR_GRAPHQL_URL}`,
      { query: sessionMetricesQuery.replace("SESSIONIDS", sessionIds.join(",")), variables: {} },
      axiosOptions);
    let metrices = apiData2.data.data.sessionMetricesBySessionIds.data;
    if (metrices.sessionMetricesBySessionIds) {
      metrices = metrices.sessionMetricesBySessionIds
    }

    // update values
    setSessions(tempSessions);
    setSessionMetrices(metrices);
    setAllVehicles(vehicleData);
    setTotalCount(total);
    currentPage = nextToken;

    let items = [];
    let active = nextToken - 1;
    const totalPages = Math.ceil(total / pageLimit);

    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => onPageItemClick(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPaginationItems(items);
  }

  function onPageItemClick(index) {
    currentPage = Number(index);
    fetchJourneys();
  }

  const gotToVehicleDetails = (id) => {
    history.push("/vehicleDetails?vehicleId=" + id);
  };

  const gotToJourneyDetails = (sessionId, vehicleId) => {
    history.push(
      `/journeyDetails?journeyId=${sessionId}&vehicleId=${vehicleId}`
    );
  };

  const drawDisabled = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gray" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M11.354 4.646a.5.5 0 0 0-.708 0l-6 6a.5.5 0 0 0 .708.708l6-6a.5.5 0 0 0 0-.708z" />
      </svg>);
  }

  function getSessionDuration(session) {
    if (session.end)
      return getDuration(session.start, session.end);
    else
      return <>
        <div style={{ color: 'red' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="red" viewBox="0 0 16 16">
            <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
          </svg>{' '}LIVE</div>
      </>
  }

  return (
    <>
      <h1>All Journeys ({totalCount})</h1>
      <br />
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Vehicle</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Total Distance</th>
            <th>Distance (Manual)</th>
            <th>Distance (Auto)</th>
            <th>Distance (Teleop)</th>
            <th>Duration</th>
            <th>Errors</th>
            <th style={{ 'minWidth': '185px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sessions.map((session, index) => {
            let vehicle = allVehicles.find((x) => x.id === session.vehicle_id);
            let currentMetrices = sessionMetrices ? sessionMetrices.find(
              (x) => x.SessionId === session.id
            ) : null;
            const isRestricted = sessionMetrices && sessionMetrices.find(x => x.SessionId === 'restricted');

            if (isRestricted) {
              return (
                <tr key={"session-" + session.id}>
                  <td>{vehicle ? vehicle.registration_number : "N/A"}</td>
                  <td>{formatDateTime(session.start)}</td>
                  <td>{formatDateTime(session.end)}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td style={{textAlign: 'center'}}>{drawDisabled()}</td>
                  <td>{getSessionDuration(session)}</td>
                  <td>{session.errors_count}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() =>
                        gotToJourneyDetails(session.id, session.vehicle_id)
                      }
                    >
                      Details
                    </Button>
                    <span> </span>
                    <Button
                      variant="success"
                      onClick={() => gotToVehicleDetails(session.vehicle_id)}
                    >
                      Vehicle
                    </Button>{" "}
                  </td>
                </tr>
              );

            } else {
              return (
                <tr key={"session-" + session.id}>
                  <td>{vehicle ? vehicle.registration_number : "N/A"}</td>
                  <td>{formatDateTime(session.start)}</td>
                  <td>{formatDateTime(session.end)}</td>
                  <td>{currentMetrices && currentMetrices.TotalDistance ? `${currentMetrices.TotalDistance !== 'restricted' ? currentMetrices.TotalDistance : roundDecimal(currentMetrices.TotalDistance, 2)} Km` : "N/A"}</td>
                  <td>{currentMetrices && currentMetrices.TotalDistanceInManual ? `${currentMetrices.TotalDistanceInManual !== 'restricted' ? currentMetrices.TotalDistanceInManual : roundDecimal(currentMetrices.TotalDistanceInManual, 2)} Km` : "0 Km"}</td>
                  <td>{currentMetrices && currentMetrices.TotalDistanceInAuto ? `${currentMetrices.TotalDistanceInAuto !== 'restricted' ? currentMetrices.TotalDistanceInAuto : roundDecimal(currentMetrices.TotalDistanceInAuto, 2)} Km` : "0 Km"}</td>
                  <td>{currentMetrices && currentMetrices.TotalDistanceInTeleop ? `${currentMetrices.TotalDistanceInTeleop !== 'restricted' ? currentMetrices.TotalDistanceInTeleop : roundDecimal(currentMetrices.TotalDistanceInTeleop, 2)} Km` : "0 Km"}</td>
                  <td>{getSessionDuration(session)}</td>
                  <td>{session.errors_count}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() =>
                        gotToJourneyDetails(session.id, session.vehicle_id)
                      }
                    >
                      Details
                    </Button>
                    <span> </span>
                    <Button
                      variant="success"
                      onClick={() => gotToVehicleDetails(session.vehicle_id)}
                    >
                      Vehicle
                    </Button>{" "}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
      <Pagination>{paginationItems}</Pagination>
    </>
  );
};

const VehicleJourneys = () => (
  <Container>
    <VehicleJourneysInner />
  </Container>
);

export default VehicleJourneys;
