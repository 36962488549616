import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
  Modal
} from "react-bootstrap";
import { getClientProfile, getDecisionEngine } from '../../utils/helper'

const SoftwareCollectionsInner = ({ children }) => {

  const [displayName, setDisplayName] = useState('');
  const [detailsField, setDetailsField] = useState([]);
  const [softwareCollections, setSoftwareCollections] = useState([]);
  const [client, setClientId] = useState('');
  const [clients, setClients] = useState([]);
  // const [outputValue, setOutputValue] = useState('');

  const handleDisplayNameChange = (e) => { setDisplayName(e.target.value) }
  const handleEditedDisplayNameChange = (e) => { setEditedSoftwareCollectionName(e.target.value) }
  const handleDetailsChange = (e) => { setDetailsField(JSON.parse(e.target.value)) }
  const handleClientChange = (e) => { setClientId(e.target.value) } 

  const [show, setShow] = useState(false);
  const [editedSoftwareCollectionDetails, setEditedSoftwareCollectionDetails] = useState({});
  const [editedSoftwareCollectionId, setEditedSoftwareCollectionId] = useState('');
  const [editedSoftwareCollectionName, setEditedSoftwareCollectionName] = useState('');

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchData();
  };

  const softwareCollectionDetailshandleChange = (e) => {
    setEditedSoftwareCollectionDetails(e.target.value)
  }

  const onEditClick = (softwareCollectionId, softwareCollectionName, softwareCollectionJson) => {
    
    setEditedSoftwareCollectionDetails(JSON.parse(JSON.stringify(softwareCollectionJson)))
    setEditedSoftwareCollectionId(softwareCollectionId)
    setEditedSoftwareCollectionName(softwareCollectionName)
    handleShow()
  }

  async function fetchData() {
    const clientQuery = `
    {
      clients {
        id
        client_name
      }
    }`;

    let clientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0'){
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access software collection.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access software collection for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          clientId = res.client
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1"){
      return
    }

    setDisplayName('');
    setSoftwareCollections([]);

    const demoData = [{ "id": 0, "hash": "ca72cc662626a", "name": "Aslan", "type": "firstparty", "vendor": "StreetDrone", "version": "1.0", "configuration": "ftp.streetdrone.com/config/aslan.yml" }, { "id": 1, "hash": "b8fdc88e86e", "name": "Ubuntu", "type": "os", "vendor": "Canonical", "version": "18.1 LTS", "configuration": "ftp.streetdrone.com/config/ubuntu.zip" }];
    setDetailsField(demoData);

    const mainQuery = `
    {
      softwareCollections (clientId: "${clientId}") {
        id
        details
        display_name
        created_at
        updated_at
      }
    }`;
    const apiData = await API.graphql(graphqlOperation(mainQuery));
    const softwareCollectionsData = apiData.data.softwareCollections;

    setSoftwareCollections(softwareCollectionsData);

    await getDecisionEngine('hub---create-config-file').then(
      async results => {
      //setOutputValue(results.outputValue);
      console.log(JSON.stringify(results), 'config access rule')
      if (results.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a software collection.</div>`
      }
      else if( results.outputValue === '1' && clientId !== '' ) {
        document.getElementById('api-result').innerHTML = `<div class="yellow">User has privilege to create a software collection only under ${clientId} client.</div>`
      }
  })
  }

  const saveSoftwareCollection = () => {

    const addSoftwareCollection = `
      mutation($display_name: String!, $details: AWSJSON!) {
        editSoftwareCollection(software_collection_id: "${editedSoftwareCollectionId}" , details: $details, display_name: $display_name) {
          id
          display_name
          details
        }
      }
    `;
    API.graphql({ query: addSoftwareCollection, variables: { 
       display_name: editedSoftwareCollectionName, details: JSON.stringify(JSON.parse(editedSoftwareCollectionDetails), null, "\t")} })
      .then((e) => {
        //console.log(e);
        handleClose()
        fetchData();
        
      });

  }

  const onAddNewClick = () => {
    console.log('Sending api call:\nDisplay Name: ', displayName, '\nDetails: ', JSON.stringify(detailsField));

    const addSoftwareCollection = `
      mutation($display_name: String!, $details: AWSJSON!, $client_id: String!) {
        addSoftwareCollection(details: $details, display_name: $display_name, client_id: $client_id) {
          id
          display_name
          details
        }
      }
    `;
    API.graphql({ query: addSoftwareCollection, variables: { display_name: displayName, details: JSON.stringify(detailsField), client_id: client } })
      .then((e) => {
        //console.log(e);
        fetchData();
      });
  }

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Id</th>
            <th>Display Name</th>
            <th>Details</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th style={{ 'minWidth': '185px' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {softwareCollections.map((softwareCollection, index) => {
            return (
              <tr key={"vehicle-" + softwareCollection.id}>
                <td>{softwareCollection.id}</td>
                <td>{softwareCollection.display_name}</td>
                <td>{JSON.stringify(JSON.parse(softwareCollection.details), null, "\t")}</td>
                <td>{softwareCollection.created_at}</td>
                <td>{softwareCollection.updated_at}</td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => onEditClick(softwareCollection.id, softwareCollection.display_name, softwareCollection.details)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Form>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Add new Software Collection:</Card.Header>
              <Card.Body>
              { clients.length > 0 && <InputGroup className="mb-3">
                  <InputGroup.Text id="HardwareCollectionId">Client</InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleClientChange}>
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clients.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.client_name}
                        </option>
                      )
                    }
                 </Form.Select>
                </InputGroup> }
                <InputGroup className="mb-3">
                  <InputGroup.Text id="displayName">Display Name</InputGroup.Text>
                  <FormControl
                    aria-describedby="displayName"
                    value={displayName}
                    onChange={handleDisplayNameChange}
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Details</InputGroup.Text>
                  <FormControl
                    as="textarea"
                    aria-label="{JSON}"
                    value={JSON.stringify(detailsField)}
                    onChange={handleDetailsChange}
                    rows={3}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={onAddNewClick}>
                  Add New
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Software Collection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <InputGroup className="mb-3">
                  <InputGroup.Text id="displayName">Display Name</InputGroup.Text>
                  <FormControl
                    aria-describedby="displayName"
                    value={editedSoftwareCollectionName}
                    onChange={handleEditedDisplayNameChange}
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                <InputGroup.Text id="displayName">Details</InputGroup.Text>
                  <FormControl
                              as="textarea"
                              aria-label="{JSON}"
                              value={JSON.parse(JSON.stringify(editedSoftwareCollectionDetails))}
                              onChange={softwareCollectionDetailshandleChange}
                              rows={6}
                            />
                </InputGroup>                            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={saveSoftwareCollection}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const SoftwareCollections = () => (
  <Container>
    <h1>Software Collections</h1>
    <SoftwareCollectionsInner />
  </Container>
);

export default SoftwareCollections;
