import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Form,
  Table,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  FormControl,
} from "react-bootstrap";
import { getClientProfile, getDecisionEngine } from '../../utils/helper'

const HardwareCollectionsInner = ({ children }) => {

  const [displayName, setDisplayName] = useState('');
  const [detailsField, setDetailsField] = useState([]);
  const [hardwareCollections, setHardwareCollections] = useState([]);
  const [client, setClientId] = useState('');
  const [clients, setClients] = useState([]);
  const [outputValue, setOutputValue] = useState('');

  const handleDisplayNameChange = (e) => { setDisplayName(e.target.value) }
  const handleDetailsChange = (e) => { setDetailsField(JSON.parse(e.target.value)) }
  const handleClientChange = (e) => { setClientId(e.target.value) } 

  const clientQuery = `
  {
    clients {
      id
      client_name
    }
  }`;

 

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefreshClick = () => {
    fetchData();
  };

  async function fetchData() {
    let clientId = ''
    await getDecisionEngine('hub---view-vehicle-data').then(async mainResponse => {
      if (mainResponse.outputValue === '0'){
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to access hardware collection.</div>`
        clientId = "-1"
      } else if (mainResponse.outputValue === '1') {
        const clients = await API.graphql(graphqlOperation(clientQuery));
        setClients(clients.data.clients)
        document.getElementById('api-result').innerHTML = `<div class='green'>User has full privilege to access hardware collection for any client.</div>`
      } else if (mainResponse.outputValue === '2') {
        await getClientProfile().then(async res => {
          clientId = res.client
        })
      } else {
        document.getElementById('api-result').innerHTML = `<div class='red'>Error, No User data found.</div>`
        clientId = "-1"
      }
    });

    if (clientId === "-1"){
      return
    }

    setDisplayName('');
    setHardwareCollections([]);

    const demoData = [{ "id": 0, "make": "Luminar", "type": "sensor", "name": "lidar", "model": "vx123", "amount": 1, "version": "1.0", "configuration": "standard", "firmwareVersion": "1.0" }, { "id": 1, "make": "Bosch", "name": "camera", "model": "mpc3", "amount": 4, "version": "1.0", "configuration": "full-coverage", "firmwareVersion": "1.0" }];
    setDetailsField(demoData);

    const mainQuery = `
    {
      hardwareCollections (clientId: "${clientId}") {
        id
        details
        display_name
        created_at
        updated_at
      }
    }`;

    const apiData = await API.graphql(graphqlOperation(mainQuery));
    const hardwareCollectionsData = apiData.data.hardwareCollections;

    setHardwareCollections(hardwareCollectionsData);

    await getDecisionEngine('hub---create-config-file').then(
      async results => {
      setOutputValue(results.outputValue);
      console.log(JSON.stringify(results), 'config access rule')
      if (results.outputValue === '0') {
        document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a hardware collection.</div>`
      }
      else if( results.outputValue === '1' && clientId !== ''  ) {
        document.getElementById('api-result').innerHTML = `<div class="yellow">User has privilege to create a hardware collection only under ${clientId} client.</div>`
      }
  })
  }


  const onAddNewClick = () => {
    if( outputValue === '0') {
      document.getElementById('api-result').innerHTML = `<div class='red'>User is not allowed to create a hardware collection.</div>`
      return
    }
    console.log('Sending api call:\n', JSON.stringify(detailsField));

    const addHardwareCollection = `
      mutation($display_name: String!, $details: AWSJSON!, $client_id: String!) {
        addHardwareCollection(details: $details, display_name: $display_name, client_id: $client_id) {
          id
          display_name
          details
        }
    }
    `;
    API.graphql({ query: addHardwareCollection, variables: { display_name: displayName, details: JSON.stringify(detailsField), client_id: client } })
      .then((e) => {
        //console.log(e);
        fetchData();
      });

  }

  return (
    <>
      <Row>
        <Col><div id="api-result"></div></Col>
        <Col></Col>
        <Col md="auto"></Col>
        <Col xs lg="1">
          <Button variant="warning" onClick={onRefreshClick}>
            Refresh
          </Button>
        </Col>
      </Row>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr key="header">
            <th>Id</th>
            <th>Display Name</th>
            <th>Details</th>
            <th>Created At</th>
            <th>Updated At</th>
            {/* <th style={{ 'minWidth': '185px' }}>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {hardwareCollections.map((hardwareCollection, index) => {
            return (
              <tr key={"vehicle-" + hardwareCollection.id}>
                <td>{hardwareCollection.id}</td>
                <td>{hardwareCollection.display_name}</td>
                <td>{JSON.stringify(JSON.parse(hardwareCollection.details), null, "\t")}</td>
                <td>{hardwareCollection.created_at}</td>
                <td>{hardwareCollection.updated_at}</td>
                {/* <td>
                  <Button
                    variant="info"
                    onClick={() => gotToDetails(vehicle.vehicle_id)}
                  >
                    Details
                  </Button>
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Form>
        <Row>
          <Col>
            <Card>
              <Card.Header as="h5">Add new Hardware Collection:</Card.Header>
              <Card.Body>
              { clients.length > 0 && <InputGroup className="mb-3">
                  <InputGroup.Text id="HardwareCollectionId">Client</InputGroup.Text>
                  <Form.Select aria-label="" onChange={handleClientChange}>
                    <option defaultValue={true}>[Select a Client]</option>
                    {
                      clients.map(s =>
                        <option value={s.id} key={s.id}>
                          {s.client_name}
                        </option>
                      )
                    }
                </Form.Select>
                </InputGroup> }
                <InputGroup className="mb-3">
                  <InputGroup.Text id="displayName">Display Name</InputGroup.Text>
                  <FormControl
                    aria-describedby="displayName"
                    value={displayName}
                    onChange={handleDisplayNameChange}
                  />
               </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Details</InputGroup.Text>
                  <FormControl
                    as="textarea"
                    aria-label="{JSON}"
                    value={JSON.stringify(detailsField)}
                    onChange={handleDetailsChange}
                    rows={3}
                  />
                </InputGroup>
              </Card.Body>
              <Card.Footer>
                <Button variant="primary" onClick={onAddNewClick}>
                  Add New
                </Button>
              </Card.Footer>
            </Card>

          </Col>
        </Row>
      </Form>

    </>
  );
};

const HardwareCollections = () => (
  <Container>
    <h1>Hardware Collections</h1>
    <HardwareCollectionsInner  />
  </Container>
);

export default HardwareCollections;
